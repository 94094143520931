<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col cols="4">
          <v-autocomplete
            label="Filter Session Date"
            :items="allSessions"
            item-text="date"
            item-value="date"
            v-model="table.search"
          ></v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        :search="table.search"
        :headers="table.headers"
        :items="allMemberVerify"
        :loading="allMemberVerify.length === 0"
        @click:row="editData"
        show-group-by
      >
        <!-- <template v-slot:item.action="{ item }">
          <v-tooltip left transition="slide-x-reverse-transition">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on" @click="editData(item)">
                <v-icon>mdi-file-edit</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
        </template> -->

        <template v-slot:item.verified="{ item }">
          <v-tooltip bottom transition="slide-y-transition">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="setStatusColor(item.verified)">
                {{ item.verified | verifyStatusIcon }}
              </v-icon>
            </template>
            <span>
              {{ item.verified | verifyStatusText }}
            </span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-col>

    <v-dialog max-width="700" v-model="id_card.show">
      <v-card max-width="700" width="auto">
        <v-img contain :src="id_card.url">
          <v-toolbar color="transparent" flat>
            <v-spacer></v-spacer>
            <v-btn icon color="error" @click="closeImage">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-img>
      </v-card>
    </v-dialog>

    <v-dialog width="1000" v-model="edit.dialog">
      <v-card :loading="edit.loading" :disabled="edit.disable">
        <v-toolbar flat>
          <v-toolbar-title>Edit Member Verification</v-toolbar-title>

          <v-tooltip right transition="slide-x-transition">
            <template v-slot:activator="{ on }">
              <v-btn
                v-if="edit.data"
                icon
                class="ml-2"
                v-on="on"
                :loading="id_card.loading"
                :disabled="id_card.change || id_card.loading"
                @click="getImage"
              >
                <v-icon>mdi-card-account-details-outline</v-icon>
              </v-btn>
            </template>
            <span>Show ID Card</span>
          </v-tooltip>

          <v-spacer></v-spacer>

          <v-btn color="error" @click="edit.dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text v-if="edit.data">
          <v-form ref="edit">
            <v-row>
              <v-col>
                <v-autocomplete
                  label="Session ID"
                  v-model="edit.data.session_id"
                  :items="allSessions"
                  item-text="date"
                  item-value="id"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-text-field
                  label="Website ID"
                  v-model="edit.data.website_id"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Name"
                  v-model="edit.data.name"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  label="Phone"
                  v-model="edit.data.phone"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-autocomplete
                  label="Status"
                  :items="category"
                  item-text="text"
                  item-value="value"
                  v-model="edit.data.status"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Upline"
                  v-model="edit.data.upline"
                  :items="edit.usernames.list"
                  item-text="username"
                  item-value="username"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Verified"
                  :items="verified"
                  item-text="text"
                  item-value="value"
                  v-model="edit.data.verified"
                ></v-autocomplete>
              </v-col>
              <v-col>
                <v-autocomplete
                  label="Agent ID"
                  :items="allAgents"
                  item-text="name"
                  item-value="id"
                  v-model="edit.data.agent_id"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-autocomplete
                  label="District"
                  v-if="!isForeign"
                  :items="districts"
                  item-text="city_name"
                  item-value="city_name"
                  v-model="edit.data.district"
                ></v-autocomplete>
                <v-text-field
                  label="District"
                  v-if="isForeign"
                  v-model="edit.data.district"
                ></v-text-field>
                <v-checkbox
                  label="Luar Negeri"
                  v-model="isForeign"
                  @change="
                    edit.data.region = isForeign ? 'foreign' : 'domestic'
                  "
                  class="mt-0"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-textarea
                  label="Address"
                  v-model="edit.data.address"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row no-gutters>
            <v-col>
              <v-btn
                color="indigo accent-2"
                @click="handleIdCard('change')"
                v-if="!id_card.change"
                depressed
              >
                <span class="white--text">change id card</span>
              </v-btn>

              <v-form ref="file" v-if="id_card.change">
                <v-file-input
                  label="ID Card"
                  accept="image/*"
                  v-model="edit.data.id_card_image"
                  :rules="edit.rules.file"
                >
                  <template v-slot:append-outer>
                    <v-tooltip right transition="slide-x-transition">
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on" @click="handleIdCard('cancel')">
                          <v-icon>mdi-close</v-icon>
                        </v-btn>
                      </template>
                      <span>Batal</span>
                    </v-tooltip>
                  </template>
                </v-file-input>
              </v-form>
            </v-col>
            <v-col>
              <v-row no-gutters class="align-center fill-height">
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="error"
                  class="mr-2"
                  @click="del.dialog = true"
                >
                  <span>delete</span>
                  <v-icon right>mdi-trash-can</v-icon>
                </v-btn>
                <v-btn color="indigo" @click="saveData">
                  <span class="white--text">save</span>
                  <v-icon right color="white">mdi-content-save</v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="del.dialog" width="600" persistent>
      <v-alert dark class="mb-0" color="warning" icon="mdi-alert" prominent>
        <v-row no-gutters class="align-center">
          Are You Sure Want To Delete This Data?
          <v-spacer></v-spacer>
          <v-btn
            text
            class="mr-2"
            :loading="del.loading"
            :disabled="del.loading"
            @click="handleDelete"
          >
            <span>yes</span>
          </v-btn>
          <v-btn depressed @click="del.dialog = false">
            <span>cancel</span>
          </v-btn>
        </v-row>
      </v-alert>
    </v-dialog>

    <v-snackbar v-model="snackbar.show" :color="snackbar.color">
      <v-icon left>
        {{ snackbar.icon }}
      </v-icon>

      {{ snackbar.text }}

      <template v-slot:action>
        <v-btn text @click="snackbar.show = false">
          <span>close</span>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
import axios from "axios";
import moment from "moment";
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      table: {
        search: null,
        headers: [
          { text: "Session ", value: "session.date" },
          { text: "Website ID", value: "website_id" },
          { text: "Name", value: "name" },
          { text: "Phone", value: "phone" },
          { text: "Address", value: "address" },
          { text: "District", value: "district" },
          { text: "Status", value: "status" },
          { text: "Region", value: "region" },
          { text: "Upline", value: "upline" },
          { text: "Verified", value: "verified" },
          { text: "Agent", value: "agent.name" },
        ],
      },
      verified: [
        { text: "Yes", value: "yes" },
        { text: "Pending", value: "pending" },
        { text: "No", value: "no" },
      ],
      category: [
        { text: "Reseller", value: "Reseller" },
        { text: "Distributor", value: "Distributor" },
        { text: "Agen", value: "Agen" },
      ],
      id_card: {
        loading: false,
        show: false,
        url: null,
        old_image: null,
        change: false,
      },
      edit: {
        usernames: {
          list: null,
        },
        dialog: false,
        loading: false,
        disable: false,
        data: null,
        rules: {
          file: [
            (value) =>
              !value || value.size < 1000000 || "File must be less than 1MB",
          ],
        },
      },
      del: {
        dialog: false,
        loading: false,
        disable: false,
        data: null,
      },
      links: {
        dialog: false,
        loading: false,
        disable: false,
        data: null,
      },
      snackbar: {
        show: false,
        color: null,
        text: null,
        icon: null,
      },
      districts: null,
      isForeign: false,
    };
  },
  methods: {
    ...mapActions(["updateMemberVerify", "deleteMemberVerify"]),
    async fetchUsernames() {
      this.loading = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/member/username`)
        .then((response) => {
          this.loading = false;
          this.edit.usernames.list = response.data;
          this.edit.usernames.list.unshift({ username: "Pusat" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    editData(item) {
      // console.log(item);
      this.id_card.old_image = item.id_card_image;
      if (item.region == "foreign") {
        this.isForeign = true;
      } else {
        this.isForeign = false;
      }
      this.edit.dialog = true;
      this.edit.data = item;
      if (!this.districts) {
        this.fetchDistricts();
      }
      if (!this.edit.usernames.list) {
        this.fetchUsernames();
      }
    },
    async fetchDistricts() {
      this.edit.loading = true;
      this.edit.disable = true;
      await axios
        .get(`${process.env.VUE_APP_API_URL}/api/kota`)
        .then((response) => {
          this.edit.loading = false;
          this.edit.disable = false;
          this.districts = response.data;
          // console.log(this.districts);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getImage() {
      this.id_card.loading = true;
      let id = this.edit.data.id;

      // console.log(data);
      await axios
        .get(
          `${process.env.VUE_APP_API_URL}/api/member/verification/${id}/id-card`,
          {
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("token")}`,
            },
          }
        )
        .then((response) => {
          this.id_card.url = response.data;
          this.id_card.show = true;
          this.id_card.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.id_card.loading = false;
        });
    },
    saveData() {
      this.edit.loading = true;
      this.edit.disable = true;
      let data = this.edit.data;

      console.log(data);
      if (this.edit.data.id_card_image && this.id_card.change) {
        let isValid = this.$refs.file.validate();
        let formData = new FormData();

        formData.append("id", data.id);
        formData.append("session_id", data.session_id);
        formData.append("website_id", data.website_id);
        formData.append("name", data.name);
        formData.append("phone", data.phone);
        formData.append("address", data.address);
        formData.append("district", data.district);
        formData.append("status", data.status);
        formData.append("region", data.region);
        formData.append("upline", data.upline);
        formData.append("verified", data.verified);
        formData.append("agent_id", data.agent_id);
        formData.append("id_card_image", data.id_card_image);

        if (isValid) {
          // for (let item of formData.values()) {
          //   console.log(item);
          // }
          this.updateMemberVerify(formData)
            .then(() => {
              this.edit.loading = false;
              this.edit.disable = false;
              this.edit.dialog = false;
              this.handleIdCard("cancel");
              this.showNotification("success");
            })
            .catch((err) => {
              this.edit.loading = false;
              this.edit.disable = false;
              this.showNotification("error");
              console.log(err);
            });
        }
      } else {
        // console.log(data);
        this.updateMemberVerify(data)
          .then(() => {
            this.edit.loading = false;
            this.edit.disable = false;
            this.showNotification("success");
          })
          .catch((err) => {
            this.edit.loading = false;
            this.edit.disable = false;
            this.showNotification("error");
            console.log(err);
          });
      }
    },
    handleIdCard(act) {
      switch (act) {
        case "change":
          this.edit.data.id_card_image = null;
          this.id_card.change = true;
          break;
        case "cancel":
          this.id_card.change = false;
          this.edit.data.id_card_image = this.id_card.old_image;
          break;
      }
      // console.log(this.edit.data);
    },
    showNotification(status) {
      this.snackbar.show = true;
      switch (status) {
        case "success":
          this.snackbar.color = "success";
          this.snackbar.icon = "mdi-check-circle";
          this.snackbar.text = "Data has been updated";
          break;
        case "error":
          this.snackbar.color = "error";
          this.snackbar.icon = "mdi-close-circle";
          this.snackbar.text = "Failed update. An error occured";
          break;
      }
    },
    setStatusColor(value) {
      switch (value) {
        case "yes":
          return "success";
        case "no":
          return "orange darken-1";
        case "pending":
          return "warning";
      }
    },
    closeImage() {
      this.id_card.url = null;
      this.id_card.show = false;
      // console.log(this.id_card)
    },
    handleDelete() {
      let data = this.edit.data;
      this.del.loading = true;
      this.deleteMemberVerify(data)
        .then(() => {
          this.del.loading = false;
          this.showNotification("success");
          this.del.dialog = false;
          this.edit.dialog = false;
        })
        .catch((err) => {
          this.del.loading = false;
          this.showNotification("error");
          console.log(err);
        });
    },
  },
  computed: {
    ...mapGetters(["allMemberVerify", "allSessions", "allAgents"]),
  },
  filters: {
    formatDate: (value) => {
      return moment(value).format("dddd, Do MMM YYYY");
    },
    capitalize: (value) => {
      return value
        .toLowerCase()
        .replace(/\w/, (firstLetter) => firstLetter.toUpperCase());
    },
    verifyStatusIcon: (value) => {
      switch (value) {
        case "yes":
          return "mdi-account-check";
        case "no":
          return "mdi-account-alert";
        case "pending":
          return "mdi-account-clock";
      }
    },
    verifyStatusText: (value) => {
      switch (value) {
        case "yes":
          return "Member Verified";
        case "no":
          return "Verify This Member";
        case "pending":
          return "Reverify This Member";
      }
    },
  },
};
</script>